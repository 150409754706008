import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Image } from 'react-frontier';
import { cropString } from '@arema/components/Util';
import { PLACEHOLDER_IMG } from '../PublicConfig';
import classNames from 'classnames';
import Visibility from 'react-on-screen';

interface EventItemProps{
	placeholder?: boolean,
	className?: string
	url?: string,
	headerLeft?: any,
	headerRight?: any,
	poster?: string,
	name?: string,
	meta?: string[],
}

var EventItem = (props: EventItemProps)=>{
	if(props.placeholder){
		return <div className={classNames("ar event-item placeholder", props.className)}>
			<div className="header">
				<div className="left placeholder"></div>
				<div className="right placeholder"></div>
			</div>
			<div className="placeholder image">
				<div className="loading"></div>
			</div>
			<div className="info">
				<div className="name placeholder"></div>
				<div className="meta placeholder"></div>
			</div>
		</div>
	}

	return <Link className={classNames("ar event-item", props.className)} to={props.url}>
		<div className="header">
			<div className="left">{props.headerLeft || ''}</div>
			<div className="right">{props.headerRight || ''}</div>
		</div>
		<Visibility partialVisibility once>
			{({ isVisible })=>(
				isVisible ? (
					<Image src={props.poster} fallback={PLACEHOLDER_IMG} />
				) : (
					<div className="placeholder image">
						<div className="loading"></div>
					</div>
				)
			)}
		</Visibility>
		<div className="info">
			<div className="name">{cropString(props.name || '', 55, true)}</div>
			{!!props.meta && props.meta.map((a,i)=>(
				<div className="meta" key={`EVITM-${i}`}>{a}</div>
			))}
		</div>
	</Link>
}

export default EventItem;