import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { addCommas, bindClose, bindFormChange, getRefundValidator, isJWT } from '@arema/components/Util';
import { BankTransferForm, Groupper, Header, Message } from '@arema/components';
import { TicketItems } from '../components';
import { Button, Table } from 'react-frontier';
import { BankTransferData, SetLoading } from '@arema/components/Classes';
import { Modal } from 'semantic-ui-react';
import Validator from '@arema/components/Validator';
import Toast from 'react-hot-toast';
import API from '../PublicAPI';
import moment from 'moment';

interface RefundData{
	order_hash: string,
	other_cost: number,
	tickets: {
		ticket_hash: string,
		event_id: number,
		event_name: string,
		section_name: string,
		price_name: string,
		date: number,
		numbered: boolean,
		seat_row: string,
		seat_number: string,
		amount: number,
	}[],
	payments: {
		payment_id: number,
		method_name: string,
		amount: number,
		direct_refund: boolean,
		name_account: string,
		clabe: string,
		card_number: string,
		completed: boolean,
		date_completed: number,
		request_info: boolean,
	}[]
}

var PublicRefund = ()=>{
	var params = useParams<{ token: string }>();
	var [dataModal, setDataModal] = useState<boolean>(false);
	var [modalPrompts, setModalPrompts] = useState<string[]>(null);
	var [refundData, setRefundData] = useState<BankTransferData>(null);
	var [refund, setRefund] = useState<RefundData>(null);
	var [loadError, setLoadError] = useState<string>(null);

	useEffect(()=>{
		if(params.token && isJWT(params.token) && !refund){
			API.getRefund(params.token).then(res=>{
				if(res.error) return setLoadError(res.message);
				setRefund(res.data);
			}).catch(err=>{
				return setLoadError('Hubo un error inesperado cargando el reembolso (LCL-1)');
			});
		}
	}, []);

	var sendInfoRequest = (setLoading: SetLoading)=>{
		var { valid, prompts } = Validator(refundData, getRefundValidator(refundData.type));
		setModalPrompts(prompts);
		if(!valid) return;

		setLoading(true);
		API.sendRefundInfoRequest(params.token, refundData).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			var rfd = {...refund};
			for(var i of rfd.payments){
				i.request_info = false;
				i.direct_refund = false;
			}
			setRefund(rfd);
			setDataModal(false);
			Toast.success('Hemos recibido tu información, tu reembolso será procesado pronto.');
		}).catch(err=>{
			return setModalPrompts(['Hubo un error inesperado enviando la información de transferencia. (LCL-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	}

	if(!params.token || !isJWT(params.token)){
		return <Header
			text={'404'}
			subtext={'Página no encontrada'}
			style={{ fontSize: 100 }}
			subheaderStyle={{ fontSize: 20 }}
			containerStyle={{ marginTop: 20 }}
		/>
	}

	if(loadError){
		return <Header text='Error' subtext={loadError} />
	}

	if(!refund){
		return <Header loading text='Cargando reembolso...' />
	}

	var tickets_total = 0, completed = refund.payments.length>0;
	var request_info = false;
	for(var i of refund.tickets){
		tickets_total += i.amount;
	}
	for(var p of refund.payments){
		if(p.request_info) request_info = true;
		if(!p.completed) completed = false;
	}

	return <div>
		{!completed && !request_info ? (
			<Message style={{ maxWidth: 500, margin: 'auto', marginTop: 15 }} type='info' centered>
				<div className="header">Reembolso pendiente</div>
				Este reembolso está en fila para ser procesado. En cuanto se realice el reembolso, se le notificará a el correo ingresado. <br /> <br />
				En caso de que su reembolso sea por transferencia bancaria o reembolso a tarjeta de credito o débito, el dinero puede tomar de 3 a 5 días habiles o en algunos casos hasta el siguiente corte de la tarjeta bancaria para aparecer en tu cuenta bancaria.
			</Message>
		) : request_info ? (
			<Message style={{ maxWidth: 500, margin: 'auto', marginTop: 15 }} type='error' centered header='Información faltante'>
				Tu reembolso no pudo ser completado ya que la información del reembolso no fue válida. Te pedimos que completes tu información de transferencia para que podamos completar tu reembolso.
				<br />
				<Button text='Completar información' color='black' onClick={bindClose(setDataModal, true)} style={{ marginTop: 15 }} />
			</Message>
		) : null}
		<Groupper className="tickets" title='Boletos reembolsados' width={500} style={{ marginTop: 15, paddingBottom: 0 }} titleCentered>
			{refund.tickets.length>0 ? (
				<TicketItems tickets={refund.tickets} />
			) : (
				<Header text='Sin boletos' subtext='Este reembolso no tiene boletos' style={{ marginBottom: 25 }} />
			)}
			<table className="fr striped divided last totals table" style={{ borderBottomLeftRadius: 12, borderBottomRightRadius: 12 }}>
				<tbody>
					<tr>
						<td>Boletos</td>
						<td className="collapsing">${addCommas(tickets_total)}</td>
					</tr>
					<tr>
						<td>Otros cargos</td>
						<td className="collapsing">${addCommas(refund.other_cost)}</td>
					</tr>
					<tr className="totals">
						<td>Total a reembolsar</td>
						<td className="collapsing" style={{ color: 'brown' }}>${addCommas(tickets_total+refund.other_cost)}</td>
					</tr>
				</tbody>
			</table>
		</Groupper>
		{completed ? (
			<Message centered style={{ maxWidth: 500, margin: 'auto', marginTop: 15 }} type='success'>
				<div className="header">Reembolso completado</div>
				<p style={{ fontWeight: 'bold', marginBottom: 10 }}>El proceso de reembolso de nuestro sistema se ha completado.</p>
				Los bancos toman algunos días en reflejar este tipo de movimientos en su estado de cuenta y saldo, por lo que le recomendamos estar al pendiente de ello. Desafortunadamente AREMA no tiene forma de acelerar esta parte del proceso.
			</Message>
		) : null}
		<Groupper title='Reembolsos' width={500} style={{ marginTop: 15 }}>
			{/* <Message text={`Ya que el pago se realizó en ${data.payments.length} pagos, se necesitarán hacer ${different_deposits.length} reembolsos.`} style={{ marginBottom: 40 }} /> */}
			<Table
				fitted
				headers={['Forma de pago', 'Reembolso', 'Aplicación']}
				centeredIndexes={[1, 2]}
				data={refund.payments.map(a=>([
					a.direct_refund ? a.method_name : (
						a.card_number ? `Tarjeta **${a.card_number}` : (a.clabe ? `SPEI **${a.clabe}` : 'Error')
					),
					addCommas(a.amount),
					a.completed ? (
						<div style={{ fontWeight: 'bold', color: 'green' }}>
							<i className="check icon"></i>
							{moment.unix(a.date_completed).format('DD/MM/YY HH:mm')}
						</div>
					) : !a.request_info ? (
						<div style={{ color: 'orange', fontWeight: 'bold' }}>
							<i className="clock icon"></i>
							Pendiente
						</div>
					) : (
						<div style={{ color: 'brown', fontWeight: 'bold' }}>
							<i className="triangle exclamation icon"></i>
							Faltante
						</div>
					)
				]))}
			/>
		</Groupper>
		<Modal open={dataModal} onClose={bindClose(setDataModal)} size='tiny'>
			<Modal.Header>Información de transferencia</Modal.Header>
			<Modal.Content>
				<BankTransferForm
					data={refundData}
					onChange={bindFormChange(refundData, setRefundData)}
					onDataChange={setRefundData}
				/>
				<Message style={{ marginTop: 15 }} type='error' list={modalPrompts} />
			</Modal.Content>
			<Modal.Actions>
				<Button text='Cancelar' basic onClick={bindClose(setDataModal)} />
				<Button text='Completar' color='black' onClick={sendInfoRequest} />
			</Modal.Actions>
		</Modal>
	</div>
}

export default PublicRefund;