import React, { useEffect, useState } from 'react';
import { Button, Groupper, Header, Input, Items, Message } from '@arema/components';
import { addCommas, bindClose, bindFormChange } from '@arema/components/Util';
import { Icon, Modal } from 'semantic-ui-react';
import { SetLoading } from '@arema/components/Classes';
import Validator from '@arema/components/Validator';
import classNames from 'classnames';
import Toast from 'react-hot-toast';
import API from '../PublicAPI';
import ReCAPTCHA from 'react-google-recaptcha';
import { CAPTCHA_ACTIVE, RECAPTCHA } from '../PublicConfig';
import '@arema/components/style/arema.scss'
import { useNavigate } from 'react-router-dom';

enum OrderMedium{
	WEB = 1,
	PDV = 2,
}

enum PaymentMethod{
	CARD = 1,
	OXXO = 2,
	SPEI = 3,

	PDV_CASH = 50,
	PDV_CARD = 51,
}

interface PdvTicket{
	ticket_hash: string,
	ticket_cost: string,
}

interface OnlineForm{
	order_hash: string,
	owner_email: string,
}

const PAYMENT_METHODS_LOCALE = [
	{ id: PaymentMethod.CARD, name: 'Tarjeta bancaria', icon: 'credit card', medium: OrderMedium.WEB },
	{ id: PaymentMethod.OXXO, name: 'Pago en OXXO', icon: 'cash register', medium: OrderMedium.WEB },
	{ id: PaymentMethod.SPEI, name: 'Transferencia bancaria (SPEI)', icon: 'money bill wave', medium: OrderMedium.WEB },
	
	{ id: PaymentMethod.PDV_CARD, name: 'Tarjeta bancaria', icon: 'credit card', medium: OrderMedium.PDV },
	{ id: PaymentMethod.PDV_CASH, name: 'Efectivo', icon: 'money bill wave', medium: OrderMedium.PDV },
]

var Refund = ()=>{
	var navigate = useNavigate();
	var [medium, setMedium] = useState<OrderMedium>(null)
	var [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(null);
	var [addTicketModal, setAddTicketModal] = useState<boolean>(false);
	var [onlineForm, setOnlineForm] = useState<OnlineForm>(null);
	var [ticketForm, setTicketForm] = useState<PdvTicket>(null);
	var [pdvTickets, setPdvTickets] = useState<PdvTicket[]>(null);
	var [errorPrompts, setErrorPrompts] = useState<string[]>(null);
	var [captcha, setCaptcha] = useState<string>();
	var [modalPrompts, setModalPrompts] = useState<string[]>(null);

	var selectMedium = (mdm: OrderMedium)=>{
		return ()=>{
			setOnlineForm(null);
			setPdvTickets(null);
			setErrorPrompts(null);
			setMedium(mdm);
		}
	}

	var selectPaymentMethod = (method: number)=>{
		return ()=>{
			setPaymentMethod(method);
			setErrorPrompts(null);
			setOnlineForm({
				order_hash: '',
				owner_email: '',
			});
			setPdvTickets([]);
		}
	}

	var addTicket = ()=>{
		var { valid, prompts } = Validator({
			ticket_hash: ticketForm?.ticket_hash,
			ticket_cost: ticketForm?.ticket_cost,
		}, {
			ticket_hash: [
				{ rule: 'empty', prompt: 'Favor de ingresar el código del boleto.' },
				{ 
					rule: v=>{
						return v && v.length>=5 && v.startsWith("T");
					},
					skipEmpty: true,
					prompt: 'El código del boleto no es válido.'
				},
				{
					rule: v=>{
						if(!pdvTickets || pdvTickets.length==0 || v.length==0 || v.length<5 || !v.startsWith("T")) return true;
						return pdvTickets.findIndex(a=>a.ticket_hash.toUpperCase().trim()==v.toUpperCase().trim())==-1;
					},
					skipEmpty: true,
					prompt: `El boleto con código ${ticketForm?.ticket_hash} ya ha sido agregado anteriormente.`,
				}
			],
			ticket_cost: [
				{ rule: 'float', prompt: 'El precio del boleto no es válido.' },
				{ rule: 'min', params: [0], prompt: 'El precio del boleto debe de ser de minimo 0.', skipEmpty: true },
			]
		});
		setModalPrompts(prompts);
		if(!valid) return;
		var pt = [...(pdvTickets || [])];
		pt.push({
			ticket_hash: ticketForm.ticket_hash,
			ticket_cost: ticketForm.ticket_cost,
		})
		setErrorPrompts(null);
		setPdvTickets(pt);
		Toast.success('Se ha agregado el boleto para reembolsar.');
		setAddTicketModal(false);
	}

	var showAddTicket = ()=>{
		setTicketForm({
			ticket_hash: '',
			ticket_cost: '',
		});
		setModalPrompts(null);
		setAddTicketModal(true);
	}
	
	var removeTicket = (index: number)=>{
		return ()=>{
			var pt = [...(pdvTickets || [])];
			pt.splice(index, 1);
			setErrorPrompts(null);
			setPdvTickets(pt);
		}
	}

	var submit = (setLoading: SetLoading)=>{
		if(!medium || !paymentMethod) return;
		var form, rules;
		if(medium===OrderMedium.WEB){
			form = {
				order_hash: onlineForm?.order_hash,
				owner_email: onlineForm?.owner_email,
				captcha,
			}
			rules = {
				order_hash: [
					{ rule: 'minLength', params: [5], prompt: 'El número de compra es inválido' },
				],
				owner_email: [
					{ rule: 'email', prompt: 'El correo electrónico es inválido.' },
				],
				captcha: CAPTCHA_ACTIVE ? [
					{ rule: 'empty', prompt: 'Favor de realizar el captcha.' }
				] : []
			}
		}else if(medium===OrderMedium.PDV){
			form = {
				tickets: pdvTickets,
				captcha
			}
			rules = {
				tickets: [
					{ rule: 'minLength', params: [1], prompt: 'Favor de ingresar por lo menos 1 boleto para reembolsar.' }
				],
				captcha: CAPTCHA_ACTIVE ? [
					{ rule: 'empty', prompt: 'Favor de realizar el captcha.' }
				] : []
			}
		}

		var { valid, prompts } = Validator(form, rules as any);
		setErrorPrompts(prompts);
		if(!valid) return;
		setLoading(true);
		var fn = medium===OrderMedium.WEB ? API.createRefundWeb(onlineForm.order_hash, onlineForm.owner_email, paymentMethod, captcha) : API.createRefundPdv(pdvTickets.map(a=>({ ticket_hash: a.ticket_hash, ticket_cost: parseFloat(a.ticket_cost) })), paymentMethod, captcha);
		fn.then(res=>{
			if(res.error) return setErrorPrompts([res.message]);
			if(res.data && res.data.token){
				return navigate(`/reembolsar/${res.data.token}`);
			}else{
				return setErrorPrompts(['Hubo un error inesperado creando el reembolso. (LCL-2)']);
			}
		}).catch(err=>{
			return setErrorPrompts(['Hubo un error inesperado creando el reembolso. (LCL-1)']);
		}).finally(()=>{
			setLoading(false);
		});
	}

	var Captcha = CAPTCHA_ACTIVE ? (
		<div className="ar centered captcha" style={{ marginTop: 15, marginBottom: 15 }}>
			<ReCAPTCHA 
				hl='es-419'
				sitekey={RECAPTCHA}
				onChange={setCaptcha}
				onExpired={()=>setCaptcha(null)}
			/>
		</div>
	) : null;

	var SelectMedium = <>
		<Header size='small' text={'Reembolsar compra'} style={{ marginTop: 0 }} />
		<Message text='Selecciona la forma de compra' centered />
		<div className="fr items selectable unfitted card" style={{ marginTop: 20, marginBottom: 10 }}>
			<div className="item" onClick={selectMedium(OrderMedium.WEB)}>
				<Icon name="globe" /> Compra Web
			</div>
			<div className="item" onClick={selectMedium(OrderMedium.PDV)}>
				<i className="cash register icon"></i> Punto de venta o taquilla
			</div>
		</div>
	</>

	var available_methods = medium ? PAYMENT_METHODS_LOCALE.filter(a=>a.medium==medium) : [];
	var SelectPaymentMethod = <>
		<Header size='small' text={medium==OrderMedium.WEB ? 'Compra Web' : 'Compra en Taquilla'} style={{ marginTop: 0 }} />
		<Button iconName='arrow left' text='Regresar' size='tiny' style={{ lineHeight: '15px', margin: 'auto', display: 'block', width: 120 }} onClick={selectMedium(null)} />
		<Message centered text='Selecciona el método de pago con el cual se realizó la compra' style={{ marginTop: 15 }} />
		<div className="fr items selectable unfitted card" style={{ marginTop: 15 }}>
			{available_methods.map(a=>(
				<div className="item" onClick={selectPaymentMethod(a.id)} key={`pm-${a.id}`}>
					<i className={classNames('icon', a.icon)}></i>
					{a.name}
				</div>
			))}
		</div>
	</>

	var onOnlineFormChange = bindFormChange(onlineForm, setOnlineForm);
	var selected_payment = paymentMethod ? PAYMENT_METHODS_LOCALE.find(a=>a.id==paymentMethod) : null;
	var OrderInfoWeb = paymentMethod ? <>
		<Header size='small' text={'Compra Web'} style={{ marginTop: 0 }} />
		<Button iconName='arrow left' text='Regresar' size='tiny' style={{ lineHeight: '15px', margin: 'auto', display: 'block', width: 120 }} onClick={()=>setPaymentMethod(null)} />
		<Message centered style={{ marginTop: 15, marginBottom: 15 }}>
			Ingresa la información de la orden
			<div style={{ color: 'gray', marginTop: 5 }}>
				<i className={classNames('icon', selected_payment.icon)}></i> {selected_payment.name}
			</div>
		</Message>
		<Input label='Número de compra' placeholder='Ej. U334GI07, D2L4M006, L300RV0C...' value={onlineForm?.order_hash} onChange={onOnlineFormChange('order_hash')} />
		<Input label='Correo electrónico' comment='El correo electrónico a donde se le enviaron los boletos' value={onlineForm?.owner_email} onChange={onOnlineFormChange('owner_email')} />
		{Captcha}
		<Message list={errorPrompts} type='error' />
		<div className="actions">
			<Button text='Enviar' color='black' onClick={submit} />
		</div>
	</> : null

	var OrderInfoPdv = paymentMethod ? <>
		<Header size='small' text={'Compra en Taquilla'} style={{ marginTop: 0 }} />
		<Button iconName='arrow left' text='Regresar' size='tiny' style={{ lineHeight: '15px', margin: 'auto', display: 'block', width: 120 }} onClick={()=>setPaymentMethod(null)} />
		<Message centered style={{ marginTop: 15, marginBottom: 15 }}>
			Agrega los boletos que se desean reembolsar
			<div style={{ color: 'gray', marginTop: 5 }}>
				<i className={classNames('icon', selected_payment.icon)}></i> {selected_payment.name}
			</div>
		</Message>
		<Button iconName='plus circle' text='Agregar boleto' color='orange' size='small' style={{ display: 'block', margin: 'auto', width: 200 }} onClick={showAddTicket} />
		<table className="fr table" style={{ marginTop: 15 }}>
			<thead>
				<tr>
					<th>Boleto</th>
					<th>Precio</th>
					<th><Icon name='remove circle' /></th>
				</tr>
			</thead>
			<tbody>
				{pdvTickets && pdvTickets.length>0 ? (
					pdvTickets.map((a, i)=>(
						<tr key={`PDVTK-${a.ticket_hash}-${i}`}>
							<td>{a.ticket_hash.toUpperCase()}</td>
							<td className='collapsing'>{addCommas(parseFloat(a.ticket_cost))}</td>
							<td className='collapsing' onClick={removeTicket(i)}>
								<Button icon iconName='remove' style={{ lineHeight: '15px' }} />
							</td>
						</tr>
					))
				) : (
					<tr>
						<td colSpan={3} className='empty'>
							No has agregado boletos para reembolsar
						</td>
					</tr>
				)}
			</tbody>
		</table>
		{Captcha}
		<Message list={errorPrompts} type='error' style={{ marginTop: 15 }} />
		<div className="actions">
			<Button text='Enviar' color='black' onClick={submit} disabled={!pdvTickets || pdvTickets.length==0} />
		</div>
	</> : null;

	var onTicketFormChange = bindFormChange(ticketForm, setTicketForm);
	
	return <div>
		<Groupper title='Solicitar reembolso' titleCentered width={500} style={{ marginTop: 20 }}>
			{!medium ? SelectMedium : (
				!paymentMethod ? SelectPaymentMethod : (
					medium==OrderMedium.WEB ? OrderInfoWeb : OrderInfoPdv
				)
			)}
		</Groupper>
		<Message style={{ maxWidth: 500, margin: 'auto', marginTop: 15 }}>
			<b>Por ningún motivo</b> se reembolsarán los cargos por servicio, envío o comisiones bancarias. 
			Solo se realizará el reembolso del costo del boleto. No se realizarán reembolsos parciales. 
		</Message>
		<Modal open={addTicketModal} onClose={bindClose(setAddTicketModal)} size='mini'>
			<Modal.Header>Agregar boleto</Modal.Header>
			<Modal.Content>
				<Input label='Código de boleto' value={ticketForm?.ticket_hash} onChange={onTicketFormChange('ticket_hash')} />
				<Input label="Precio del boleto" placeholder='MXN' value={ticketForm?.ticket_cost} onChange={onTicketFormChange('ticket_cost')} />
				<Message type='error' list={modalPrompts} />
			</Modal.Content>
			<Modal.Actions>
				<Button text='Cerrar' basic onClick={bindClose(setAddTicketModal)} />
				<Button text='Guardar' color='black' onClick={addTicket} />
			</Modal.Actions>
		</Modal>
	</div>
}

export default Refund;