import React, { useEffect, useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { BankTransferData, DepositType } from '../Classes';
import { Field, Input, Message } from 'react-frontier';
import { bindFormChange, formatCreditCard, getBankParticipants } from '../Util';
import { clabe } from 'clabe-validator';

interface FormProps{
	data: BankTransferData,
	onDataChange: (data: BankTransferData)=>void,
	onChange: (name: keyof BankTransferData, semantic?: boolean)=>(val: any)=>void,
}

var BankTransferForm = (props: FormProps)=>{
	useEffect(()=>{
		if(!props.data){
			props.onDataChange({
				email: '',
				type: null,
				account_owner: '',
				clabe: '',
				card_number: '',
				bank: '',
			})
		}
	}, [props.data]);
	
	if(!props.data) return null;
	return <>
		<Field label='Tipo de transferencia'>
			<Dropdown selection placeholder='Tipo de transferencia' value={props.data.type} onChange={props.onChange('type', true)} options={[
				{ text: 'Transferencia bancaria (SPEI)', value: DepositType.SPEI },
				{ text: 'Deposito a tarjeta bancaria', value: DepositType.CARD }
			]} />
		</Field>
		{props.data.type===DepositType.SPEI ? (
			<>
				<Input label='Propietario de cuenta' value={props.data.account_owner} onChange={props.onChange('account_owner')} />
				<Input label='CLABE' value={props.data.clabe} onChange={props.onChange('clabe')} />
				<Input label='Banco' readonly value={clabe.validate(props.data.clabe).bank} comment='Este campo se llena automáticamente' />
			</>
		) : props.data.type===DepositType.CARD ? (
			<>
				<Input label='Nombre en la tarjeta' value={props.data.account_owner} onChange={props.onChange('account_owner')} />
				<Input label="Número de la tarjeta" value={props.data.card_number} onChange={props.onChange('card_number')} valueFormat={formatCreditCard} maxLength={16+3} />
				<Field label='Banco'>
					<Dropdown search selection selectOnBlur={false} value={props.data.bank} onChange={props.onChange('bank', true)} placeholder='Banco de la tarjeta' options={getBankParticipants().map((a,i)=>({
						text: a.bank_name,
						value: a.participant,
						key: `${a.participant}-${i}`
					}))} />
				</Field>
				<Message type='info' style={{ marginBottom: 10, marginTop: 15 }}>
					<ul>
						<li>Para evitar cualquier tipo de problema, <b>favor de ingresar solo tarjetas de débito</b>.</li>
						<li>Por el momento solo podemos hacer reembolsos a <b>tarjetas de instituciones bancarias mexicanas</b>.</li>
					</ul>
				</Message>
			</>
		) : null}
	</>
}

export default BankTransferForm;