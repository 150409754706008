const CDN_URL = process.env.REACT_APP_CDN_URL;
const CDN_ROOT = process.env.REACT_APP_CDN_ROOT;
const PAGE_PUBLIC = process.env.REACT_APP_PAGE_PUBLIC;
const PAGE_CHECKOUT = process.env.REACT_APP_PAGE_CHECKOUT;
const API_URL = process.env.REACT_APP_API_URL;
const DEBUG = process.env.REACT_APP_DEBUG;
const PLACEHOLDER_IMG = 'https://cdn.arema.dev/assets/evento.png';
const RECAPTCHA = process.env.REACT_APP_RECAPTCHA || null;
const CAPTCHA_ACTIVE = process.env.REACT_APP_CAPTCHA_ACTIVE!='0';


export {
	API_URL,
	CAPTCHA_ACTIVE,
	CDN_ROOT,
	CDN_URL,
	DEBUG,
	PAGE_CHECKOUT,
	PAGE_PUBLIC,
	PLACEHOLDER_IMG,	
	RECAPTCHA,
}