import React, { useEffect, useState } from 'react';
import { Image } from 'react-frontier';
import { PLACEHOLDER_IMG } from '../PublicConfig';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { randomRange, uniqueArray } from '@arema/components/Util';
import remarkGfm from 'remark-gfm';

interface EventHeaderProps{
	poster?: string,
	data?: {
		name: string,
		cities?: string[],
		sinopsis: string,
	},
	onClickImage?: ()=>void,
}

var EventHeader = (props: EventHeaderProps)=>{
	if(!props.data){
		return <div className='ar event-header placeholder'>
			<div className="left">
				{!!props.poster ? (
					<Image src={props.poster} onClick={props.onClickImage} fallback={PLACEHOLDER_IMG} />
				) : (
					<Image src={PLACEHOLDER_IMG} />
				)}
			</div>
			<div className="right">
				<div className="title"></div>
				<div className="cities"></div>
				<div className="sinopsis">
					{(new Array(32).fill('arema')).map((a,i)=>(
						<div key={`descplhldr-${i}`} className={`placeholder size${randomRange(1,8)}`}></div>
					))}
				</div>
			</div>
		</div>
	}
	return <div className="ar event-header">
		<div className="left">
			{!!props.poster ? (
				<Image src={props.poster} onClick={props.onClickImage} fallback={PLACEHOLDER_IMG} />
			) : (
				<Image src={PLACEHOLDER_IMG} />
			)}
		</div>
		<div className="right">
			<div className="title">{props.data.name}</div>
			{!!props.data.cities && props.data.cities.length>0 && (
				<div className="cities">{uniqueArray(props.data.cities).join(', ')}</div>
			)}
			<div className="sinopsis">
				<ReactMarkdown linkTarget={'_blank'} remarkPlugins={[remarkGfm]} children={props.data.sinopsis} />
			</div>
		</div>
	</div>
}

export default EventHeader;