import React, { useEffect, useState } from 'react';
import { Groupper } from '@arema/components';
import { useTitle } from '@arema/components/Hooks';
import { Icon, SemanticICONS } from 'semantic-ui-react';
import classNames from 'classnames';

import '../style/nosotros.scss';

function IconSection(props: { icon: SemanticICONS, children: any, top?: boolean, title: string }){
	return <>
		<Groupper.Divider text={props.title} className={classNames({
			top: props.top
		})} />
		<div className='section'>
			<div className="left">
				<i className={classNames("icon", props.icon)}></i>
			</div>
			<div className="content">
				{props.children}
			</div>
		</div>
	</>
}

var Nosotros = ()=>{
	var { setTitle } = useTitle();

	useEffect(()=>{
		setTitle('Nosotros');
	}, []);
	
	return <div className='ar nosotros-screen'>
		<Groupper title='Nosotros' titleSize='big' titleCentered style={{ maxWidth: 600, marginTop: 15 }}>
			<IconSection title='¿Que hacemos?' icon="ticket alternate" top>
				Desarrollamos aplicaciones completas de boletaje electrónico para la industria del entretenimiento, brindando servicios que incluyen:
				<ul>
					<li>Expedición de boletos en sitio</li>
					<li>Venta de boletos por internet</li>
					<li>Venta de boletos en tiendas propias</li>
					<li>Control de acceso en las puertas de los recintos</li>
					<li>Sistemas de lealtad</li>
					<li>Servicios de “mailing”</li>
					<li>Consulta de información de venta de eventos vía web</li>
					<li>Fabricación de boletos con imagen personalizada (aplican restricciones)</li>
				</ul>
			</IconSection>
			<IconSection title='Contacto' icon="address book">
				Contáctanos para cualquier duda o problema que tengas en nuestra plataforma
				<Icon name="facebook" /> <a href="https://facebook.com/aremamx">facebook.com/aremamx</a>
				<br />
				<Icon name="mail" /> <a href="mailto:hola@arema.mx">hola@arema.mx</a>
			</IconSection>
			<IconSection title='Empresa mexicana' icon="flag">
				Somos una empresa <b>100% mexicana</b>, muy orgullosos creadores de tecnología mexicana desde <b>1990.</b> 
			</IconSection>
			<IconSection title='Empresa de Monterrey' icon="building">
				Nacimos y tenemos nuestra oficina central en la Ciudad de Monterrey, aunque trabajamos con eventos en muchas ciudades de la República Mexicana, 
				como Saltillo, Tijuana, Queretaro, CDMX, San Luis Potosi, Puebla, Toluca, Pachuca, Merida, Leon, etc.
			</IconSection>
			<IconSection title='Representantes' icon="user">
				Contamos también con un representante en Mexicali, Tuxtla Gutiérrez, Durango, San Luis Potosí y Monclova. 
			</IconSection>
			<IconSection title='Únete' icon="handshake">
				Únase a nuestra familia de recintos, promotores y clientes satisfechos, visítenos frecuentemente. Día a día nos esmeramos en romper paradigmas. 
			</IconSection>
			<IconSection title='Reembolsos' icon={"hand holding usd" as SemanticICONS}>
				Los <b>reembolsos para eventos cancelados</b> se están procesando a medida que las solicitudes de reembolso se hagan siguiendo las instrucciones en <a href="/reembolsos">nuestra página de reembolsos</a>. 
				Actualmente no se están procesando solicitudes para eventos reprogramados o en proceso de cambio de fecha.
				<br /> <br />
				Los clientes que hayan comprado boleto para un evento tendrán una ventana de tiempo de 30 días calendario después del anuncio de cambio de fecha o de cancelación para solicitar el reembolso, o en su defecto, 
				5 días hábiles antes de la nueva fecha programada de realización del evento. AREMA no podrá, por logística y las indicaciones de las empresas promotoras, hacerse responsable de ningún reemebolso que no haya sido 
				solicitado dentro de la ventana de tiempo especificada. 
			</IconSection>
			<IconSection title='Redes sociales' icon='world'>
				<Icon name="facebook" /> <a href="https://facebook.com/aremamx">Facebook</a>
				<br />
				<Icon name="twitter" /> <a href="https://twitter.com/aremamx">Twitter</a>
				<br />
				<Icon name="instagram" /> <a href="https://instagram.com/aremamx">Instagram</a>
				<br />
				<Icon name="world" /> <a href="https://tiktok.com/aremamx">TikTok</a>
			</IconSection>
		</Groupper>
	</div>
}

export default Nosotros;