import React, { useEffect, useState } from 'react';
import { Header } from 'react-frontier';

var NotFound = ()=>{
	return <Header
		text={'404'}
		subtext={'Página no encontrada'}
		style={{ fontSize: 100 }}
		subheaderStyle={{ fontSize: 20 }}
		containerStyle={{ marginTop: 20 }}
	/>
}

export default NotFound;