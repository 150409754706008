import React, { useEffect, useState } from 'react';
import { CDN_URL, PLACEHOLDER_IMG } from '../PublicConfig';
import { Image } from '@arema/components';
import { formatSeatNumber, addCommas } from '@arema/components/Util';
import moment from 'moment';

interface Props{
	tickets: {
		ticket_hash: string,
		event_id: number,
		event_name: string,
		date: number,
		section_name: string,
		price_name: string,
		numbered?: boolean,
		seat_section?: string,
		seat_row?: string,
		seat_number?: string,
		ticket_cost?: number,
		amount?: number,
	}[]
}

var TicketItems = (props: Props)=>{
	useEffect(()=>{
		
	}, []);
	
	return <div className="fr items small first tickets divided">
		{props.tickets.map(a=>(
			<div className="item" key={`tkt-${a.ticket_hash}`}>
				<Image src={`${CDN_URL}/events/${a.event_id}/800.webp`} alt={a.event_name} fallback={PLACEHOLDER_IMG} />
				<div className="info">
					<div className="event">{a.event_name}</div>
					<div className="date">{moment.unix(a.date).format('DD/MMM/YY HH:mm')}</div>
					<div className="section meta">
						{a.section_name} - {a.price_name}
					</div>
					{a.numbered ? (
						<div className="seat meta">
							{a.seat_section ? (`${a.seat_section} - `) : 'Butaca'} {formatSeatNumber(a.seat_row, a.seat_number)}
						</div>
					) : null}
					<div className="cost">${addCommas(a.ticket_cost || a.amount)}</div>
				</div>
			</div>
		))}
	</div>
}

export default TicketItems;