import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { EventHeader, NotFound } from '../components';
import { SubdomainResponse } from '@arema/components/Classes';
import API from '../PublicAPI';
import PublicEvent from './PublicEvent';
import PublicEventGroup from './PublicEventGroup';

var PublicSubdomain = ()=>{
	var { subdomain } = useParams();
	var [notFound, setNotFound] = useState<boolean>(null);
	var [data, setData] = useState<SubdomainResponse>(null);
	var [loadError, setLoadError] = useState<string>(null);
	var subdomain_reg = subdomain ? (/^@(?<s>[a-z0-9]{3,32})$/gi).exec(subdomain) : null;
	
	useEffect(()=>{
		if(subdomain_reg && subdomain_reg.groups?.s){
			setNotFound(false);
			API.getSubdomain(subdomain_reg.groups.s.trim().toLowerCase()).then(res=>{
				if(res.error || !res.data) return setNotFound(true);
				setData(res.data);
				setNotFound(false);
			}).catch(err=>{
				setNotFound(true);
			});
		}
	}, [subdomain]);

	if(!subdomain_reg || !subdomain_reg.groups?.s || notFound){
		return <NotFound />
	}

	if(!data) return <EventHeader />
	if(data.event_id){
		return <PublicEvent event_id={data.event_id} legacy={data.legacy} />
	}else if(data.group_id){
		return <PublicEventGroup group_id={data.group_id} legacy={data.legacy} />
	}else{
		return <NotFound />
	}
}

export default PublicSubdomain;